<template>
  <div>
    <div class="imgImportBox" v-loading="loading">
      <!-- <el-progress
        v-if="isUploading"
        type="circle"
        :percentage="percentage"
        :width="178"
        :status="percentage === 100 ? 'success' : undefined"
      /> -->
      <!-- <img class="avatar" v-for="item in imgData" :src="item.image" :key="item.id" alt=""> -->
      <!-- {{ data }} -->
      <ul class="el-upload-list el-upload-list--picture-card">
        <li v-for="(item, index) in filelist" :key="index" tabindex="0" class="el-upload-list__item is-success" :draggable="true"
            @dragstart="handleDragStart($event, item)"
            @dragover.prevent="handleDragOver($event, item)"
            @dragenter="handleDragEnter($event, item)"
            @dragend="handleDragEnd($event, item)">
          <img :src="item.url" class="el-upload-list__item-thumbnail" />
          <a class="el-upload-list__item-name"><i class="el-icon-document"></i></a>
          <label v-if="item.url" class="el-upload-list__item-status-label"><i class="el-icon-upload-success el-icon-check"></i></label>
          <i class="el-icon-close"></i>
          <span class="el-upload-list__item-actions">
            <span class="el-upload-list__item-preview" @click="handlePictureCardPreview(item)"><i class="el-icon-zoom-in"></i></span>
            <span class="el-upload-list__item-delete" @click="() => handleRemove(item)"><i class="el-icon-delete"></i></span>
          </span>
        </li>
      </ul>
      <el-upload
        v-show="filelist.length < limit"
        action="#"
        class="importUpload"
        list-type="picture-card"
        :accept="acceptType"
        :limit="limit"
        :on-success="onSuccess"
        :on-remove="handleRemove"
        :http-request="onChange"
        :file-list="filelist"
        :show-file-list="false"
        :before-upload="beforeAvatarUpload"
      >
        <i class="el-icon-plus"></i>
      </el-upload>
      <el-dialog :visible.sync="dialogVisible">
        <img class="bigSizeImage" width="100%" :src="dialogImageUrl" alt="" />
      </el-dialog>
    </div>
    <div style="color: #aaa; font-size: 13px; line-height: 18px;" :style="{marginTop: filelist.length > 0 ? '0px' : '10px'}">
      {{ promptText ? promptText : $t('message.iamgeNote') }}
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
import { postImgData } from '@/services/product'
export default {
  props: {
    limit: {
      type: Number,
      default () {
        return 10
      }
    },
    data: {
      default () {
        return null
      }
    },
    watchDataVisible: {
      type: Boolean,
      default () {
        return true
      }
    },
    index: {
      type: Number,
      default () {
        return 0
      }
    },
    name: {
      type: String,
      default () {
        return 'image_url'
      }
    },
    Object: {
      type: Object,
      default () {
        return {}
      }
    },
    promptText: {
      type: String,
      default: null
    }
  },
  watch: {
    index: {
      handler () {
        this.clear()
      }
    },
    data (newV, oldV) {
      if (!this.watchDataVisible) return
      // if (newV === null) return
      if ((newV && newV.toString()) !== (oldV && oldV.toString())) {
        if (this.visible === false) return
        this.$nextTick(() => {
          let dataArr = this.data
          if (!dataArr) return
          if (!(dataArr instanceof Array)) {
            let arr = []
            arr = [{ id: Date.now(), [this.name]: this.data }]
            dataArr = arr
          }
          dataArr.forEach(item => {
            if (!item.idIndex) {
              if (this.limit === 1 && item[this.name] === null) return
              this.filelist.push({ url: item[this.name], id: item.id, image: item[this.name], display_order: item.display_order || 0 })
            }
          })
          // console.log('************', this.filelist)
          this.fileData = _.cloneDeep(this.filelist) // 深拷贝
          this.visible = false
          this.$emit('change', this.fileData)
        })
      }
    }
    // data: {
    //   deep: true,
    //   handler (n, o) {
    //     console.log(n.toString() === o.toString())
    //   }
    // }
  },
  created () {
    this.acceptType = this.Object.acceptType ? this.Object.acceptType : this.acceptType
    let dataArr = this.data
    if (dataArr) {
      if (dataArr && !(dataArr instanceof Array)) {
        let arr = []
        arr = [{ id: Date.now(), [this.name]: this.data }]
        dataArr = arr
      }
      dataArr && dataArr.forEach(item => {
        if (!item.idIndex) {
          if (this.limit === 1 && item[this.name] === null) return
          // if (!item.image_url === null) {
          this.filelist.push({ url: item[this.name], id: item.id, image: item[this.name], display_order: item.display_order || 0 })
          // }
        }
      })
      this.fileData = _.cloneDeep(this.filelist) // 深拷贝
      this.$emit('change', this.fileData)
    }
  },
  data () {
    return {
      loading: false,
      visible: true,
      dialogImageUrl: '',
      dialogVisible: false,
      filelist: [], // 展示数据
      fileData: [], // 传出的数据,
      acceptType: '.jpg,.png,.jpeg,JPG,JPEG,.mp3,.m4a,.wav,.gif,.webp',
      drapVisible: this.limit > 1,
      dragging: null
    }
  },
  methods: {
    handleDragStart (e, item) {
      this.dragging = item
    },
    handleDragEnd (e, item) {
      this.dragging = null
    },
    // 首先把li变成可以放置的元素，即重写dragenter/dragover
    handleDragOver (e) {
      e.dataTransfer.dropEffect = 'move' // 在dragenter中针对放置目标来设置!
    },
    handleDragEnter (e, item) {
      e.dataTransfer.effectAllowed = 'move' // 为需要移动的元素设置dragstart事件
      if (item === this.dragging) {
        return
      }
      const newItems = [...this.filelist]
      const src = newItems.indexOf(this.dragging)
      const dst = newItems.indexOf(item)
      const centerDats = newItems[src].display_order
      newItems[src].display_order = newItems[dst].display_order
      newItems[dst].display_order = centerDats
      newItems.splice(dst, 0, ...newItems.splice(src, 1))
      this.filelist = newItems
      const data = this.fileData.filter(item => item.display_order === -1)
      this.fileData = _.cloneDeep(this.filelist) // 深拷贝
      this.fileData = this.fileData.concat(data)
      this.$emit('change', this.fileData)
    },
    handleRemove (file) {
      // console.log(file, fileList)
      // this.fileData = _.cloneDeep(this.filelist) // 深拷贝
      const index = this.filelist.findIndex(item => item.uid === file.uid)
      let num
      if (file.id) {
        num = this.fileData.findIndex(item => item.id === file.id)
      } else {
        num = this.fileData.findIndex(item => item.uid === file.uid)
      }
      if (this.fileData[num].id) {
        this.fileData[num]._destroy = true //this.fileData[num].id
        this.fileData[num].modifyFile = true
        this.fileData[num].display_order = -1
      } else {
        this.fileData.splice(num, 1)
      }
      this.filelist.splice(index, 1)
      this.filelist.forEach((res, filelistIndex) => {
        res.display_order = filelistIndex
      })
      this.fileData.forEach((res, fileDataIndex) => {
        if (res.display_order !== -1) res.display_order = fileDataIndex
      })
      this.$emit('change', this.fileData)
    },
    handlePictureCardPreview (file) {
      this.dialogImageUrl = file.url
      this.dialogVisible = true
    },
    beforeAvatarUpload (file) {
      let dataType = ['image/jpeg', 'image/png', 'image/gif', 'image/webp']
      if (this.Object.uploadType) {
        dataType = []
        this.Object.uploadType.forEach(res => {
          dataType.push(res)
        })
      }
      const isType = dataType.includes(file.type)
      const isLtSize = file.size / 1024 / 1024 < (this.Object.uploadSize ? this.Object.uploadSize : 5)

      if (!isType) {
        this.$message.error(this.$t('message.uploadMessageType', { type: dataType.join(',') }))
        return false
      }
      if (!isLtSize) {
        this.$message.error(this.$t('message.uploadMessageSize', { num: this.Object.uploadSize ? this.Object.uploadSize : 5 }))
        return false
      }
      return isType && isLtSize
    },
    async onChange (params) {
      const formdata = new FormData()
      formdata.append('image[image]', params.file)
      this.loading = true
      try {
        const res = await postImgData(formdata)
        this.loading = false
        if (res.status === 200) {
          if (this.limit === 1) {
            this.filelist[0] = { uid: params.file.uid, url: res.data.image, image: res.data.image, modifyFile: true, display_order: 0 }
            this.fileData[0] = { uid: params.file.uid, url: res.data.image, image: res.data.image, modifyFile: true, display_order: 0 }
          } else {
            this.filelist.push({ uid: params.file.uid, url: res.data.image, image: res.data.image, modifyFile: true, display_order: this.filelist.length })
            this.fileData.push({ uid: params.file.uid, url: res.data.image, image: res.data.image, modifyFile: true, display_order: this.fileData.length })
          }
          this.$forceUpdate()
        }
        this.visible = false
        this.$emit('change', this.fileData)
      } catch (error) {
        this.loading = false
      }
    },
    onSuccess (file) {
    },
    update () {
      this.data.forEach(item => {
        if (!item.idIndex) {
          if (this.limit === 1 && item[this.name] === null) return
          this.filelist.push({ url: item[this.name], id: item.id, image: item[this.name] })
        }
      })
      this.fileData = _.cloneDeep(this.filelist) // 深拷贝
      this.$emit('change', this.fileData)
    },
    clear () {
      this.filelist = []
      if (this.limit === 1) {
        this.fileData = []
      } else {
        const arr = []
        this.fileData.forEach(item => {
          if (item.id) {
            arr.push(Object.assign(item, { _destroy: item.id, modifyFile: true }))
          }
        })
        this.fileData = arr
      }
    }
  }
}
</script>

<style lang="scss" scope>
.imgImportBox {
  display: flex;
  > img {
    margin-right: 8px;
    transition: all 1s ease-in-out;
  }
  .importUpload .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
    // width: 120px;
    // height: 120px;
    // display: flex;
    // align-items: center;
    // justify-content: center;
  }
}
// .importUpload .el-upload:hover {
//   border-color: #409EFF;
// }
// .avatar-uploader-icon {
//   font-size: 20px;
//   color: #8c939d;
//   width: 120px;
//   height: 120px;
//   line-height: 120px;
//   text-align: center;
// }
// .img-uploader-icon {
//   font-size: 30px;
//   color: #8c939d;
//   width: 300px;
//   height: 150px;
//   line-height: 150px;
//   text-align: center;
// }
.bigSizeImage {
  object-fit: contain;
  min-width: 100px;
  min-height: 100px;
  max-width: 100%;
  max-height: 100%;
}
.avatar {
  width: 120px;
  height: 120px;
  display: block;
}
</style>
